<template>
   
   <div class="Client">
        <!-- BEGIN: Content-->
        <div class="app-content content ">
            <div class="content-overlay"></div>
            <div class="header-navbar-shadow"></div>
            <div class="content-wrapper container-xxl p-0">

                <banner title="Income Tax Return Filing" :breadcrumb="[
                    { label: 'individual ', url: 'http://localhost:8080/retailer/client' },
                    { label: 'Other Informations '},
                ]">
                </banner>


                <div class="row" style="margin-top: -45px;">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding-right: 5px;">
                        <div class="card">
                            <div class="card-body">
                                <form @submit.prevent="saveAllInformation">
                                    <h3>Step-3 : Other Informations</h3>
                                    <div class="progress progress-bar-danger">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="50"
                                            aria-valuemin="50" aria-valuemax="100" style="width: 50%">50%</div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <!-- <div class="col-xl-6 col-md-6 col-12">
                                                    <div class="form-group">
                                                        <input @change="uploadPhoto" type="file"
                                                            style="position:absolute;top:-150000px;" id="photo">
                                                        <label for="photo">
                                                            <img style="width:100%;height:100px;" :src="photoImg" alt="">
                                                        </label>
                                                    </div>
                                                </div> -->
                                        <!-- <div class="col-6"></div> -->
                                        <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px;">
                                            <div class="form-group">
                                                <label>Mobile Number</label>
                                                <input style="height:30px;" type="tel" minlength="10" maxlength="10"
                                                    @keyup="checkDetailByPhone" v-model="phone" class="form-control"
                                                    placeholder="Mobile Number" required />
                                            </div>
                                        </div>


                                        <div class="col-xl-6 col-md-6 col-6" style="padding: 5px 5px 5px 5px;">
                                            <div class="form-group">
                                                <label>Pan</label>
                                                <input style="height:30px;" type="tel" maxlength="10" v-model="panNo"
                                                    class="form-control" placeholder="Pen number of Partner" required />
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-md-6 col-6" style="padding: 5px 5px 5px 5px;">
                                            <div class="form-group">
                                                <label>Aadhar</label>
                                                <input style="height:30px;" type="tel" maxlength="12" v-model="aadharNo"
                                                    class="form-control" placeholder="Aadhar Number" />
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px;">
                                            <div class="form-group">
                                                <label>DOB</label>
                                                <input style="height:30px;" type="date" v-model="dob"
                                                    class="form-control" placeholder="Date Of Birth" />
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-md-6 col-6" style="padding: 5px 5px 5px 5px;">
                                            <div class="form-group">
                                                <label>First Name</label>
                                                <input style="height:30px;" type="text" v-model="fname"
                                                    class="form-control" placeholder="First name" required />
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-md-6 col-6" style="padding: 5px 5px 5px 5px;">
                                            <div class="form-group">
                                                <label>Last Name</label>
                                                <input style="height:30px;" type="text" v-model="lname"
                                                    class="form-control" placeholder="Last Name" />
                                            </div>
                                        </div>

                                        <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px;">
                                            <div class="form-group">
                                                <label>FATHER'S NAME</label>
                                                <input style="height:30px;" type="text" v-model="fatherName"
                                                    class="form-control" placeholder="FATHER'S NAME" required />
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px;">
                                            <div class="form-group">
                                                <label>Email</label>
                                                <input style="height:30px;" type="email" v-model="email"
                                                    class="form-control" placeholder="Email" />
                                            </div>
                                        </div>
                                        <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px;">
                                            <div class="form-group">
                                                <label>Password</label>
                                                <input style="height:30px;" type="text" v-model="itrPassword"
                                                    class="form-control"
                                                    placeholder="Income Tax Password (if available)" />
                                            </div>
                                        </div>
                                    </div>
                                    <h3>SubTotals : ₹ {{ serviceCharge }}</h3>
                                    <br>
                                    <div class="row">
                                        <div class="col-6 text-start">
                                            <!-- <router-link to="/partnership2"> -->
                                            <button type="button" @click="redirectBack" class="btn btn-sm  text-white"
                                                style="background-color:#00364f;width:70%;">
                                                BACK</button>
                                            <!-- </router-link> -->
                                        </div>
                                        <div class="col-6 text-end">

                                            <button type="submit" class="btn btn-sm text-white"
                                                style="background-color:#f21300;width:70%;">Save & Next
                                            </button>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="heading">FAQ's</h2>
                                        <div class="accordion accordion-margin accordion-border" id="accordionMargin">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingMarginOne">
                                                    <button class="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#accordionMarginOne"
                                                        aria-expanded="false" aria-controls="accordionMarginOne">
                                                        <i data-feather='plus' class="accordian-plus"></i> What
                                                        is Salary Income?
                                                    </button>
                                                </h2>
                                                <div id="accordionMarginOne" class="accordion-collapse collapse"
                                                    aria-labelledby="headingMarginOne"
                                                    data-bs-parent="#accordionMargin">
                                                    <div class="accordion-body">
                                                        Pastry pudding cookie toffee bonbon jujubes jujubes
                                                        powder topping. Jelly beans gummi bears sweet roll
                                                        bonbon muffin liquorice. Wafer lollipop sesame snaps.
                                                        Brownie macaroon cookie muffin cupcake candy
                                                        caramels tiramisu. Oat cake chocolate cake sweet jelly-o
                                                        brownie biscuit marzipan. Jujubes donut
                                                        marzipan chocolate bar. Jujubes sugar plum jelly beans
                                                        tiramisu icing cheesecake.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingMarginTwo">
                                                    <button class="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#accordionMarginTwo"
                                                        aria-expanded="false" aria-controls="accordionMarginTwo">
                                                        <i data-feather='plus' class="accordian-plus"></i> What
                                                        is Capital Gain?
                                                    </button>
                                                </h2>
                                                <div id="accordionMarginTwo" class="accordion-collapse collapse"
                                                    aria-labelledby="headingMarginTwo"
                                                    data-bs-parent="#accordionMargin">
                                                    <div class="accordion-body">
                                                        Sweet pie candy jelly. Sesame snaps biscuit sugar plum.
                                                        Sweet roll topping fruitcake. Caramels
                                                        liquorice biscuit ice cream fruitcake cotton candy tart.
                                                        Donut caramels gingerbread jelly-o
                                                        gingerbread pudding. Gummi bears pastry marshmallow
                                                        candy canes pie. Pie apple pie carrot cake.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingMarginThree">
                                                    <button class="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#accordionMarginThree"
                                                        aria-expanded="false" aria-controls="accordionMarginThree">
                                                        <i data-feather='plus' class="accordian-plus"></i> What
                                                        is House Property Income?
                                                    </button>
                                                </h2>
                                                <div id="accordionMarginThree" class="accordion-collapse collapse"
                                                    aria-labelledby="headingMarginThree"
                                                    data-bs-parent="#accordionMargin">
                                                    <div class="accordion-body">
                                                        Tart gummies dragée lollipop fruitcake pastry oat cake.
                                                        Cookie jelly jelly macaroon icing jelly beans
                                                        soufflé cake sweet. Macaroon sesame snaps cheesecake
                                                        tart cake sugar plum. Dessert jelly-o sweet
                                                        muffin chocolate candy pie tootsie roll marzipan. Carrot
                                                        cake marshmallow pastry. Bonbon biscuit
                                                        pastry topping toffee dessert gummies. Topping apple pie
                                                        pie croissant cotton candy dessert tiramisu.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingMarginFour">
                                                    <button class="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#accordionMarginFour"
                                                        aria-expanded="false" aria-controls="accordionMarginFour">
                                                        <i data-feather='plus' class="accordian-plus"></i> What
                                                        is Business Income?
                                                    </button>
                                                </h2>
                                                <div id="accordionMarginFour" class="accordion-collapse collapse"
                                                    aria-labelledby="headingMarginFour"
                                                    data-bs-parent="#accordionMargin">
                                                    <div class="accordion-body">
                                                        Cheesecake muffin cupcake dragée lemon drops tiramisu
                                                        cake gummies chocolate cake. Marshmallow tart
                                                        croissant. Tart dessert tiramisu marzipan lollipop lemon
                                                        drops. Cake bonbon bonbon gummi bears topping
                                                        jelly beans brownie jujubes muffin. Donut croissant
                                                        jelly-o cake marzipan. Liquorice marzipan cookie
                                                        wafer tootsie roll. Tootsie roll sweet cupcake.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingMarginFive">
                                                    <button class="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#accordionMarginFive"
                                                        aria-expanded="false" aria-controls="accordionMarginFive">
                                                        <i data-feather='plus' class="accordian-plus"></i> What
                                                        are Other Sources of Income?
                                                    </button>
                                                </h2>
                                                <div id="accordionMarginFive" class="accordion-collapse collapse"
                                                    aria-labelledby="headingMarginFive"
                                                    data-bs-parent="#accordionMargin">
                                                    <div class="accordion-body">
                                                        Cheesecake muffin cupcake dragée lemon drops tiramisu
                                                        cake gummies chocolate cake. Marshmallow tart
                                                        croissant. Tart dessert tiramisu marzipan lollipop lemon
                                                        drops. Cake bonbon bonbon gummi bears topping
                                                        jelly beans brownie jujubes muffin. Donut croissant
                                                        jelly-o cake marzipan. Liquorice marzipan cookie
                                                        wafer tootsie roll. Tootsie roll sweet cupcake.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>

        </div>
    </div>
</template>

<script>
     import Banner from '../../../../components/retailer/comman/Banner.vue'
export default {
   
    name: 'partnership5Component',
    components: {
        Banner,
    },
    data() {
        return {
            // termCondition: 'false1'
            // photoImg: this.$store.state.placeholderImg,
            // photo: '',
            clientrecord: {},
            clientkyc: {},
            client_id: '',
            panNo: '',
            aadharNo: '',
            fname: '',
            lname: '',
            dob: '',
            fatherName: '',
            phone: null,
            email: '',
            itrPassword: '',
            serviceCharge: '',
            state_id: '',
            city: '',
            address: '',
            address2: '',
            pin: null

        }
    },
    methods: {
        uploadPhoto(e) {
            this.photoImg = URL.createObjectURL(e.target.files['0'])
            this.photo = e.target.files['0']
            //  let photo = new FormData();
            //  photo.append('photo', e.target.files['0']);
            //  this.photo.append('photo', e.target.files['0']);
            //    this.photo = photo
        },
        checkDetailByPhone() {

            if (this.phone.length === 10) {
                this.$axios
                    .get(`retailer/client?phone=${this.phone}`, { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
                    .then(res => {
                        // var id = res.data.data.data[0].id    
                        console.log(res.data)
                        this.clientrecord = res.data.data.data[0]
                        this.client_id = this.clientrecord.id
                        // this.photo = this.clientrecord.photo
                        this.fname = this.clientrecord.fname
                        this.lname = this.clientrecord.lname
                        this.email = this.clientrecord.email
                        this.fatherName = this.clientrecord.fatherName
                        this.dob = this.clientrecord.dob
                        this.state_id = this.clientrecord.state_id
                        this.city = this.clientrecord.city
                        this.address = this.clientrecord.address
                        this.address2 = this.clientrecord.address2
                        this.pin = this.clientrecord.pin
                        this.$axios
                            .get(`retailer/clientkyc?client_id=${this.clientrecord.id}`, { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
                            .then(res => {
                                // var id = res.data.data.data[0].id
                                // alert(res.data.data.data[0].id)
                                this.clientkyc = res.data.data.data[0]
                                this.aadharNo = this.clientkyc.aadharNo
                                this.panNo = this.clientkyc.panNo
                            })
                    })
            }

        },
        redirectBack() {
            this.$router.push({ name: 'partnership2Component' })
        },
        saveAllInformation() {
            // alert('hi')
            var itr = {}
            itr = JSON.parse(localStorage.getItem('itr'))
            itr.client_id = this.client_id
            itr.panNo = this.panNo
            itr.aadharNo = this.aadharNo
            itr.fname = this.fname
            itr.lname = this.lname
            itr.dob = this.dob
            itr.fatherName = this.fatherName
            itr.phone = this.phone
            itr.email = this.email
            itr.state_id = this.state_id
            itr.city = this.city
            itr.address = this.address
            itr.address2 = this.address2
            itr.pin = this.pin
            itr.itrPassword = this.itrPassword

            localStorage.setItem('itr', JSON.stringify(itr))


            this.$router.push({ name: 'Itr4' })
        }
    },
    created() {
        if (this.$route.meta.redirectIfLoggedIn == 'true' && localStorage.getItem('accessToken') == null) {
            this.$router.push({ name: 'Login' })
        } else {
            var itr = {}
            itr = JSON.parse(localStorage.getItem('itr'))
            if (itr.panNo) {
                this.panNo = itr.panNo
            }
            if (itr.aadharNo) {
                this.aadharNo = itr.aadharNo
            }
            if (itr.fname) {
                this.fname = itr.fname
            }
            if (itr.lname) {
                this.lname = itr.lname
            }
            if (itr.dob) {
                this.dob = itr.dob
            }
            if (itr.fatherName) {
                this.fatherName = itr.fatherName
            }
            if (itr.phone) {
                this.phone = itr.phone
            }
            if (itr.email) {
                this.email = itr.email
            }
            if (itr.photo) {
                this.photo = itr.photo
            }
            if (itr.serviceCharge) {
                this.serviceCharge = itr.serviceCharge
            }
        }
    },
}
</script>

<style scoped>
.accordian-plus {
    width: 20px;
    height: 20px;
    stroke: red;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
}

.bg-image {
    background-color: #fff;
    background-image: url(https://apk.mudhrape.com/wp-content/uploads/2022/01/background.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 15px 0 120px;
    /* text-align: center; */
    color: white;
    /* background-size: 100% 100%;
    width: 100%; */
}

.h2-heading {
    color: white;
    font-family: poppins, Sans-serif;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}

@media (max-width: 767px) {
    .h2-heading {
        font-size: 22px;
    }
}

.heading {
    color: #00364f;
    font-family: poppins, Sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
    padding: 20px 0;
}
</style>